import * as React from "react";
import Head from "../components/Head";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Instagram from "../components/InstagramEmbeded";
import Recruit from "../components/Recruit";
import News from "../components/News";
import FirstView from "../components/FirstView";
import TopService from "../components/TopService";
import TextSection from "../components/TextSection";
import TopPageSecondView from "../components/TopPageSecondView";
import Store from "../components/Store";
import { H2, H3, P } from "../components/BoxTypo";
import "../style/forms.css";
import "../style/base.css";
import { Box, Grid } from "@mui/material";
import { Link } from "gatsby";

const Thanks = () => {
  return (
    <>
      <Box>
        <Head imagePath="https://forustyle-985bb.web.app/ogp-top.jpg" />
        <Header isTop isBlack />
        <Box className="w80 mw1100 tac" m={"auto"} mt={"20vh"}>
          <H2>
            ご注文
            <br />
            ありがとうございます
          </H2>
          <P width="90%" maxWidth="600px" m={"auto"} textAlign="justify">
            大切な機会に、forucateringをご依頼いただき心より感謝申し上げます。
            店舗やケータリングの状況によりご予約を承ることができない場合もあります。
            3日以内に、スタッフよりメールでご連絡させていただきますのでお待ち下さい。
          </P>
          <Box pt={10} pb={10}>
            <Link
              className="btn back"
              style={{
                textDecoration: "none",
              }}
              to="/"
            >
              <Grid container alignItems={"center"} style={{ height: "100%" }}>
                <Grid item xs={3}></Grid>
                <Grid item xs={6}>
                  <P>TOPへ</P>
                </Grid>
                <Grid item xs={3}>
                  <P>{"＞"}</P>
                </Grid>
              </Grid>
            </Link>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default Thanks;
